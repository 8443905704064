* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.App {
  position: absolute;
  top: -150px;
  width: stretch;
  background-color: #ffffff;
}

.grid-container {
  margin-top: 100px;
  display: grid;
  justify-content: space-around;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
  grid-row-gap: 10px;
  list-style:none;
  padding: 10px;
}

.grid-container-video {
  display: grid;
  justify-content: space-around;
  grid-template-columns: auto auto auto;
  /* grid-template-rows: auto auto auto auto auto; */
  grid-row-gap: 10px;
  list-style:none;
  margin-top: 150px;
  /* padding: 10px; */
}


.grid-container-about {
  margin-top: 100px;
  display: grid;
  justify-content: space-around;
  grid-template-columns: auto auto;
  /* grid-template-rows: auto auto auto auto auto; */
  grid-row-gap: 10px;
  list-style:none;
  padding: 10px;
  width: 70%;
}

ul {
  display: block;
  position: relative;
  top: 60px;
  padding-bottom: 20px;
  list-style: none;
}

li {
  vertical-align:top;
  padding: 5px;
  padding-bottom: 20px;
}

a {
  text-decoration: none;
  color: 'orange'
}

.item_container img{
  width: 100%;
  padding-bottom: 0%;
}

.animation_title {
  padding-top: 150px;
  text-align: left;
}

.animation_title img {
  width: 30%;
}

.about_title {
  padding-top: 70px;
  text-align: left;
  width: 200px;
}

.about_title img {
  width: 100%;
}

.about_container {
   
  top: 0px;
  width: auto;
}

.image_info {
  /* position: absolute;
  text-justify:  */
  /* top:20px; */
  /* margin: auto,0; */
}

.item_text {
  position: relative;
  top: 50%;
  left: 20%;
  /* border-radius: 5px; */
   
  padding: 10px;
  background-color:rgba(255, 149, 87, 0.55);
  color: black;
  opacity: 1.0;
  font-size: auto;
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

p {
  position: relative;
}

em {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
  position: absolute;
  bottom: 75px;
  left: 16%;
  right: 16%;
  background:rgba(255, 149, 87, 0.55);
  color: hsl(0, 0%, 100%);
  padding: 7px;
  font-weight: bold;
  font-style: normal;
  border-radius: 6px; 
}

.special {
  width: auto;

}

.grid_Images {
  /* width: 80%;
  height: auto; */
  /* width: 100%; */ 
  box-shadow: 0 0 5px rgba(0.2,0.2,0.2,0.2); 
}

.grid_Images_transparent {
  opacity: 0.10;
  /* width: 100%; */
}

.grid_Images_zoom {
  position: fixed;
  top: 12%;
  left: 30%;
  width: 30%;
  height: auto;
  opacity: 1.0;
  /* padding: 10px; */
  box-shadow: 0 0 5px rgba(0.2,0.2,0.2,0.2); 
}
  
.reg_images {
  width: 10px;

}


.white_out {
  background-color: white;
  opacity: 0.50;
  position: fixed;
  top: 0px;
  left: 0px;
}

.alter_gridImages {
  width: 500px;
}

.about_paragraph {
  color:gray;
  text-align:left;
  width: 300px;
}

.dash_list {
  width: 90%;
}

.test_image img{
  width: 320px;
}

@media (min-width: 200px) and (max-width: 700px) {

  .grid-container {
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto;
    /* grid-template-rows: auto auto auto auto auto; */
    grid-row-gap: 10px;
    list-style: none;
    margin-top: 200px;
    padding: 10px;
  }

  .grid-container-video {
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto;
    /* grid-template-rows: auto auto auto auto auto; */
    grid-row-gap: 10px;
    list-style:none;
    /* margin-top: 10px;
    padding: 10px; */
  }

  .grid-container-about {
    margin-top: 100px;
    display: grid;
    justify-content: space-around;
    grid-template-columns: auto;
    /* grid-template-rows: auto auto auto auto auto; */
    grid-row-gap: 10px;
    list-style:none;
    padding: 10px;
    width: 100%;
  }

  .about_paragraph {
    color:gray;
    text-align:left;
    width: 300px;
  }


}

 