.bar {
  position: fixed;
  top:0px;
  left:0px;
  /* background-color: rgb(155, 150, 150); */
  min-height: 60px;
  width: stretch;
   
}

ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

li {
  text-align: center;
  float: left;
  padding-left: 55px;
}

.barText {
  margin: auto,0; 
  top: 0px;
  position: fixed;
  /* display: inline; */
  /* background-color: rgb(155, 150, 150); */
  background-color: rgb(235, 234, 232);
  font-family: Geneva,Tahoma,Verdana,sans-serif;   
  font-size: 1.0rem;
  color: rgb(102, 91, 78);
  padding-top: 15px;
  /* padding-right: 60px; */
  overflow-wrap: none;
  list-style: none; 
  box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
  width: 100%;
}



@media (min-width: 200px) and (max-width: 700px) {

  .barText {
    margin: auto,0; 
    top: 0px;
    position: fixed;
    display: flex;
    background-color: rgb(235, 234, 232);
    font-family: Geneva,Tahoma,Verdana,sans-serif;   
    font-size: 0.75rem;
    color: rgb(102, 91, 78);
    padding-top: 10px; 
    padding-left: 5px; 
    padding-right: 25px; 
    overflow-wrap: wrap;
    list-style: none; 
    box-shadow: 1px 5px 5px rgba(0,0,0,0.2); 
    width: 100%;
  }

  .bar {
    position: fixed;
    top:0px;
    left:0px;
    /* background-color: rgb(155, 150, 150); */
    height: 200px;
    width: 100%;
     
  }

  ul {
    margin: 0;
    padding: 0;
    overflow: wrap;
  }
  
  li {
    text-align: center;
    float: left;
    padding-left: 20px;
  }

}